import Contact from './Contact';
import Languages from './Languages';
import Summary from './Summary';
import Skills from './Skills';
import ppPhoto from './Images/ppPhoto.jpg'
import './App.css';

function App() {
    return (
        <div className="fullBody">
            <div className="photo">
                <img src={ppPhoto} alt="Christin" className="ppPhoto" />
            </div>
            <div className="mainBody">
                <div className="spacerColumn"></div>
                <div className="CjInfo" >

                    <div className="CjInfo-left-panel">
                        <div className="mainTitleSec">
                            <span className="mainTitle">Christin Joseph</span>
                            <br />
                            Software Engineer
                        </div>
                        <br />
                        <div className="summaryForMob">
                            <Summary />
                        </div>
                        <div className="contactForWeb">
                            <Contact />
                        </div>


                        <Skills />
                        <br/>
                        <Languages />

                    </div>

                    <div className="CjInfo-right-panel">
                        <div className="summaryForWeb">
                            <Summary />
                        </div>
                        <span className="subTitle">Work History</span>
                        <br /><br />
                        <div className="wH">
                            <div className="wH-left">2015-08 - 2022-01<br /></div>
                            <div className="wH-right">
                                <p>
                                    <b>FINASTRA</b>
                                    <br />
                                </p>
                                <p>
                                    Worked in <b>LaserPro</b>, a comprehensive loan solution popular in the United States. It is capable of processing consumer, commercial, and mortgage loan applications from top to bottom. For loan processing and decision-making, it is fully automated. The product complies with all applicable laws and regulations in the United States.
                                </p>
                                <p>
                                    Worked in <b>SBALS</b>, a small business loan product that simplifies the process in the United States. This is integrated with LaserPro. It automates the processing, generation, and electronic signature of small business financing compliance documentation.

                                    <br /><br />
                                    Responsibilities:
                                    <ul>
                                        <li>Evaluating the feasibility, complexity, and scope of each change request with product owners, legal counsel, business analysts, and other stakeholders.</li>
                                        <li>Gathering and outlining customer requirements to generate precise specifications for project plans.</li>
                                        <li>Analyzing requirements and estimating effort needed
                                            for user stories.</li>

                                        <li>Mentoring other resources in team.</li>

                                        <li>Researching on existing features and finding
                                            developmental area.</li>
                                        <li>Implement the changes, fixing bugs.</li>
                                        <li>Working in an agile development environment which
                                            enhance product development.</li>
                                        <li>Communicated with third-party product owners to
                                            upgrade existing services and to implement new
                                            services in SBALS application.</li>
                                        <li>Created different tools to enhance the product
                                            development, testing and research activities.</li>
                                        <li>Created a tool for the customers to better utilize their
                                            effort on the SBALS application.</li>
                                        <li>Implemented unit test to boost the development
                                            process of SBALS.</li>
                                    </ul>
                                </p>
                                <br />
                                Technologies and Framework: C#, C++, XAML, WPF, MVVM, REST and SOAP Web services.
                                <br />
                                <br />
                            </div>
                        </div>

                        <span className="subTitle">Education</span>
                        <br /><br />
                        <div className="edu">
                            <div className="edu-left">2011-06 - 2016-05<br /></div>
                            <div className="edu-right">
                                <p>
                                    <b>Bachelor of Technology</b>
                                    <br />
                                    Mahatma Gandhi University College of Engineering | Idukki,
                                    Kerala
                                    <br />
                                    Computer Science and Engineering
                                </p>
                            </div>
                        </div>

                        <span className="subTitle">Accomplishments</span>
                        <div className="accomplish">
                            <ul>
                                <li>Worked as a crucial member of the team that implemented the 'Pay Check Protection Program' (PPP and PPP Second Draw) - a special lending programme designed to supplement paychecks during COVID in the United States.</li>
                                <li>For establishing PPP and PPP Second Draw, I received various prizes and accolades.</li>
                                <li>Finastra Hackathon winner in 2018.</li>
                                <li>Got special mention in Hackathon 2019 (AI, ML and Angular).</li>
                                <li>Outstanding (topmost performing band) performer on a consistent basis.</li>
                                <li>Two times I received the FinStar (equivalent to best employee) award.</li>
                            </ul>
                        </div>

                        <span className="subTitle">Certifications</span>
                        <div className="cert">
                            <ul>
                                <li>AZ-900 - Microsoft Azure Fundamentals - English (ENU)</li>
                            </ul>
                        </div>
                        <div className="mobileFriendly">

                            <div className="contactForMob">
                                <Contact />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="spacerColumn"></div>
            </div>

        </div>
    );
}

export default App;