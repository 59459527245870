function Languages() {
    return (
        <div className="skills">
            <div className="subTitle skillsLabel">Languages</div>
            <br />
            <div style={{ marginLeft: "20px" }}>

                <b>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="skillGrid">
                            <div>Malayalam</div>
                            <div className="normalFont" style={{ textAlign: "right" }}> Nativ Language</div>
                            <br />                            
                        </div>

                        <div className="skillGrid">
                            <div>English</div>
                            <div className="normalFont" style={{ textAlign: "right" }}> Professional  Proficiency</div>
                            <br />                            
                        </div>

                        <div className="skillGrid">
                            <div>Hindi</div>
                            <div className="normalFont" style={{ textAlign: "right" }}> Elementary Proficiency</div> 
                            <br />
                        </div>                        

                        <div className="skillGrid">
                            <div>German </div>
                            <div className="normalFont" style={{ textAlign: "right" }}> Elementary Proficiency</div>                            
                        </div>

                    </div>
                </b>

            </div>
        </div>);
}

export default Languages;