function Summary() {
    return (
        <div className="summary">
            <p>Innovative Software Developer offering <b>6.5 years</b> of expertise in
                <b> .Net Technologies and Web Technologies</b> primarily in Banking Software
                Development. Communicates productively with both technical and nontechnical
                personals and clients. Quickly learns and masters new technologies
                while working in both team and self-directed settings. Proud team player
                focused on achieving project objectives with speed and accuracy.</p>
        </div>
    );
}
export default Summary;