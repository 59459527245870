function Skills() {
    return (
        <div className="skills">
            <div className="subTitle skillsLabel">Skills</div>
            <br />
            <div style={{ marginLeft: "20px" }}>

                <b>
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="skillGrid">
                            <div>C#</div>
                            <input type="range" value="80" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Very Good </div>
                        </div>

                        <div className="skillGrid">
                            <div>HTML, CSS</div>
                            <input type="range" value="80" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Very Good </div>
                        </div>

                        <div className="skillGrid">
                            <div>JavaScript</div>
                            <input type="range" value="60" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Good </div>
                        </div>

                        <div className="skillGrid">
                            <div>WPF</div>
                            <input type="range" value="60" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Good </div>
                        </div>

                        <div className="skillGrid">
                            <div>Agile Scrum</div>
                            <input type="range" value="60" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Good </div>
                        </div>

                        <div className="skillGrid">
                            <div>SQL</div>
                            <input type="range" value="40" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Average </div>
                        </div>

                        <div className="skillGrid">
                            <div>Azure, React.JS</div>
                            <input type="range" value="20" style={{ width: "90%" }} />
                            <div style={{ textAlign: "right", paddingRight: "10%" }}> Basic </div>
                        </div>
                    </div>
                </b>

            </div>
        </div>);
}
export default Skills;