function Contact() {
    return (

        <div className="contact">
            <div className="subTitle contactLabel"> Contact</div>
            <br />
            <div style={{ marginLeft: "20px" }}>
                <b>Address</b>
                <br />
                Wiesbaden, Hesse, Germany
                <br />
                <br />
                <b>Phone</b>
                <br />
                +49 17624912389
                <br />
                <br />
                <b>Email</b>
                <br />
                <a href="mailto:christin.official@gmail.com" style={{ color: "inherit" }}>christin.official@gmail.com</a>
                <br />
                <br />
                <b>Website</b>
                <br />
                <a href="http://www.christin.info" target="_blank" rel="noopener noreferrer" style={{ color: "inherit" }}> www.christin.info</a>
                <br />
                <br />
            </div>
        </div >

    );
}

export default Contact;